import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'

const NotFoundLayout = styled(Layout)`
  main {
    min-height: 40vh;
    margin-top: 150px;
    text-align: center;
    h2 {
      color: #1a1a1a;
      font-size: 64px;
      font-weight: 300;
      line-height: 0.94;
      margin-bottom: 20px;
    }
    a {
      color: inherit;
    }
  }
`

const NotFoundPage = () => (
  <NotFoundLayout>
    <main>
      <h2>404 Page Not Found</h2>
      <p>
        The page you requested does not exist. <Link to="/">Click here</Link> to
        browse more recipes.
      </p>
    </main>
  </NotFoundLayout>
)

export default NotFoundPage
